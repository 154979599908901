import { camelize } from 'vessel/utils'

class Fbq {
  constructor(fbq, options, ctx) {
    this.fbq         = fbq
    this.options     = options
    this.ctx         = ctx
    this.initialized = false
    this.enabled     = false
  }

  optIn() {
    this.enabled = true
    this.enable()

    this.init()
  }

  optOut() {
    this.enabled = false
    this.disable()
  }

  init() {
    if (this.enabled && !this.initialized) {
      this.initialized = true

      for (const pixel of this.pixelIds) {
        this.fbq('init', pixel)
      }
    }

    if (!this.enabled) {
      this.disable()
    }
  }

  enable() {
    this.fbq('consent', 'grant')
  }

  disable() {
    this.fbq('consent', 'revoke')
  }

  get pixelIds() {
    return this.options.pixels.map(({ id }) => id)
  }

  get activePixel() {
    return this.ctx.store.get('site/activePixel')
  }

  trackAction(name, ...args) {
    if (this.enabled) {
      if (this.activePixel) {
        this.fbq('trackSingle', this.activePixel, name, ...args)
      } else {
        this.fbq('track', name, ...args)
      }
    }
  }

  trackCustom(name, ...args) {
    if (this.enabled) {
      name = camelize(name, 'upper')

      if (this.activePixel) {
        this.fbq('trackSingleCustom', this.activePixel, name, ...args)
      } else {
        this.fbq('trackCustom', name, ...args)
      }
    }
  }

  trackEvent(name, ...args) {
    if (/phone_call|send_(fax|email)|contact_request/.test(name)) {
      return this.trackAction('Contact', ...args)
    }
    if (/(view|find)_(location|directions)/.test(name)) {
      return this.trackAction('FindLocation', ...args)
    }

    this.trackCustom(name, ...args)
  }

  trackPage() {
    if (this.enabled) {
      this.fbq('track', 'PageView')
    }
  }

  trackPurchase(data) {
    const itemIds = data.items.map(({ id }) => id)

    const args = {
      content_type: 'product',
      value: data.value,
      currency: data.currency,
      contents: data.items,
      content_ids: itemIds
    }

    this.trackAction('Purchase', args)
  }
}

export default (ctx, inject) => {
  const facebook = {"enabled":true,"pixelId":"1395058710903919","pixels":[{"id":"1395058710903919"}]}

  if (typeof window !== 'undefined') {
    /* eslint-disable */
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */

    const instance = new Fbq(fbq, facebook, ctx)
    ctx.$tracker.register('fbq', instance)

    const { route } = ctx
    const matchParam = route.query[facebook.matchParam]

    if (matchParam) {
      const pixel = facebook.pixels.find(({ param }) => param == matchParam)
      ctx.store.set('site/activePixel', pixel.id)
    }
  }
}
