
export default {
  vessel: {
    search: {
      padding: 'sm',
      borderBottomWidth: 1,
      backgroundColor: 'grey-lighter'
    }
  },
  data() {
    return {
      showSearch: false,
    }
  },
  computed: {
    desktopLinks() {
      return [
        { label: 'pages.products', route: 'categories' },
        { label: 'pages.offers', route: 'offers' },
        { label: 'pages.services', route: 'services' },
        { label: 'pages.service', route: 'service-trakter' },
        { label: 'pages.parts', href: 'http://paourisparts.gr' },
        { label: 'pages.contact', route: 'contact' }
      ]
    },
    mobileLinks() {
      return [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.products', route: 'categories' },
        { label: 'pages.offers', route: 'offers' },
        { label: 'pages.services', route: 'services' },
        { label: 'pages.service', route: 'service-trakter' },
        { label: 'pages.about', route: 'about' },
        { label: 'pages.contact', route: 'contact' },
        { label: 'pages.parts', href: 'http://paourisparts.gr' }
      ]
    }
  },
  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch

      if (this.showSearch && this.$refs.searchInput) {
        this.$nextTick(() => {
          this.$refs.searchInput.focusInput()
        })
      }
    }
  }
}
