import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _63b058ee = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _4aba675e = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e2220efe = () => interopDefault(import('../pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _fc71dd5e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _a603e132 = () => interopDefault(import('../pages/service-trakter.vue' /* webpackChunkName: "pages/service-trakter" */))
const _65992170 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _1bc25fb8 = () => interopDefault(import('../pages/applications/_id.vue' /* webpackChunkName: "pages/applications/_id" */))
const _17fc0ce1 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _c111b79e = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _35d6a946 = () => interopDefault(import('../pages/collections/_id.vue' /* webpackChunkName: "pages/collections/_id" */))
const _4849980e = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _44b764da,
    name: "about___el"
  }, {
    path: "/categories",
    component: _63b058ee,
    name: "categories___el"
  }, {
    path: "/collections",
    component: _4aba675e,
    name: "collections___el"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___el"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/en",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/offers",
    component: _e2220efe,
    name: "offers___el"
  }, {
    path: "/products",
    component: _fc71dd5e,
    name: "products___el"
  }, {
    path: "/service-trakter",
    component: _a603e132,
    name: "service-trakter___el"
  }, {
    path: "/services",
    component: _65992170,
    name: "services___el"
  }, {
    path: "/en/about",
    component: _44b764da,
    name: "about___en"
  }, {
    path: "/en/categories",
    component: _63b058ee,
    name: "categories___en"
  }, {
    path: "/en/collections",
    component: _4aba675e,
    name: "collections___en"
  }, {
    path: "/en/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/en/offers",
    component: _e2220efe,
    name: "offers___en"
  }, {
    path: "/en/products",
    component: _fc71dd5e,
    name: "products___en"
  }, {
    path: "/en/service-trakter",
    component: _a603e132,
    name: "service-trakter___en"
  }, {
    path: "/en/services",
    component: _65992170,
    name: "services___en"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/en/applications/:id?",
    component: _1bc25fb8,
    name: "applications-id___en"
  }, {
    path: "/en/brands/:id?",
    component: _17fc0ce1,
    name: "brands-id___en"
  }, {
    path: "/en/categories/:id",
    component: _c111b79e,
    name: "categories-id___en"
  }, {
    path: "/en/collections/:id",
    component: _35d6a946,
    name: "collections-id___en"
  }, {
    path: "/en/products/:id",
    component: _4849980e,
    name: "products-id___en"
  }, {
    path: "/en/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }, {
    path: "/applications/:id?",
    component: _1bc25fb8,
    name: "applications-id___el"
  }, {
    path: "/brands/:id?",
    component: _17fc0ce1,
    name: "brands-id___el"
  }, {
    path: "/categories/:id",
    component: _c111b79e,
    name: "categories-id___el"
  }, {
    path: "/collections/:id",
    component: _35d6a946,
    name: "collections-id___el"
  }, {
    path: "/products/:id",
    component: _4849980e,
    name: "products-id___el"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
